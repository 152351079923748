import React from 'react'
import { howItworksType } from '../../templates/use-cases';
import './content-with-image.css'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';


// const AppImage = ({ image }: { image: string }) => {
//     return <img src={image} alt="" style={{ width: "100%" }} loading="lazy" />
// }

function ContentWithImage({ contentWithImageData }: { contentWithImageData: { title: string, description: string, isReversed?: boolean, contentWithImage: IGatsbyImageData }[] }) {
    return (
        <div className='content-with-image'>
            {/* <h2 className='bold text-h2 text-align-center'>
                Lorem Ipsum Dolor Sit Amet
            </h2> */}
            <div className='parent-wrapper'>
                {contentWithImageData.map(each =>
                    <div
                        className={each?.isReversed ? "content-container isreversed" : "content-container"}
                        id={each.title === "Proactive Analytics" ? "proactive-analysis" : ""}
                    >
                        <div className='text-block'>
                            <h2 className='text-h2 title'>
                                {each.title}
                            </h2>
                            <p className='text-lg-new'>
                                {each.description}
                                </p>
                        </div>
                        <div className='image-block'>
                            {/* <AppImage image={unifiedImage} /> */}
                            <GatsbyImage
                                alt={''}
                                image={each.contentWithImage}
                                style={{ width: "100%" }}
                                objectFit="contain"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ContentWithImage;