import { graphql } from 'gatsby';
import React from 'react'
import AppLayout from '../../components/AppLayout';
import ContentWithImage from '../../components/ContentWithImage';
import CtaBlock from '../../components/components-group-one/CtaBlock';
import Faq from '../../components/components-group-one/faq';
import HeroSection from '../../components/components-group-one/hero-section';
import HowItWorks from '../../components/components-group-one/how-it-works';
import Reasons from '../../components/components-group-one/reason';
import RelatedUseCases from '../../components/components-group-one/RelatedCases';
import TestimonialBlock from '../../components/components-group-one/TestimonialBlock';
import UseCases from '../../templates/use-cases';
import { imageDataType } from '../prevent-escalations';

function EdgeAutomation({ data }: { data: imageDataType }) {
    const pageData = {
        seoData: {
            title: "Edge Automation: Trend Identification and Escalation Resolution",
            canonicalLink: "https://www.aptedge.io/technology/edge-automation/",
            metaDescription: {
                metaDescription: "Identify trends and issues, deflecting tickets before they arise and assigning them to the right experts. Lower escalations and volume with Aptedge Edge Automation.",
            },
            openGraphImage: {
                file: {
                    url: "",
                },
            },
        },
        title: "Edge Automation",
        description: "Trend Identification to Act and Deflect",
        isTechnology: true,
        heroImage: data.heroImg.childImageSharp.gatsbyImageData,

        reasons: {
            title: "Trend Identification to",
            subTitle: "Act and Deflect",
            content: "AptEdge Edge Automation identifies common trends in support cases and tickets linked into resolutions.  Using Edge Automation, a support team can lower ticket/support volumes by grouping common tickets for immediate deflection and reduce escalations by assigning tickets to the right team members.  Additionally, product team and executives gain key insights into trends impacting CSAT and feature requests.",
            image: data.reasonImg.childImageSharp.gatsbyImageData,
        },
        testimonial: [
            {
                company: "hexagon",
                name: "Adam Savage",
                bio: "Chief Digital Officer",
                logo: data.testimonialLogoOne.childImageSharp.gatsbyImageData,
                content: "“AptEdge automated our knowledge discovery, greatly reducing our reliance on tacit knowledge.”"
            },
            {
                company: "cloud bees",
                name: "Ryan Smith",
                bio: "Sr. Support Manager",
                logo: data.testimonialLogoTwo.childImageSharp.gatsbyImageData,
                content: "AptEdge has been key in bridging silos between tools we use on a daily basis to build software."
            },
        ],
        contentWithImageData: [
            {
                title: "Trend Identification and Escalation Resolution",
                description: "Group and resolve common tickets for deflection and faster resolution.  Allow your customer care team to do more with less.",
                contentWithImage: data.contentWithImageOne.childImageSharp.gatsbyImageData,
            },
            {
                isReversed: true,
                title: "Proactive Analytics",
                description: "AptEdge Analytics provides a holistic view of the health of the support environment by pulling in knowledge sources from multiple platforms.  Understand themes and trends by customer, product, or ticket type over time to drive efficiency and key business insights.",
                contentWithImage: data.contentWithImageTwo.childImageSharp.gatsbyImageData,
            },
        ],
        dummyImg: data.dummyImg.childImageSharp.gatsbyImageData,
    }
    return (
        <AppLayout seoData={pageData.seoData}>
            <HeroSection
                title={pageData.title}
                heroImage={pageData.heroImage}
                description={pageData.description}
                isTechnology={pageData.isTechnology}
            />
            <Reasons reasons={pageData.reasons} />
            <ContentWithImage contentWithImageData={pageData.contentWithImageData} />
            <TestimonialBlock testimonialData={pageData.testimonial} />
            {/* <Faq /> */}
            <CtaBlock ctaTitle={undefined} />
        </AppLayout>
    )
}

export default EdgeAutomation;

export const query = graphql`
    query EdgeAutomationQuery {
        reasonImg: file(relativePath: { eq: "technology/action-and-deflect.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE, quality: 100)
            }
        }
        testimonialLogoOne: file(relativePath: { eq: "utkarsh/logo-hexagon.png" }) {
          childImageSharp {
              gatsbyImageData(placeholder: NONE)
          }
      }
      testimonialLogoTwo: file(relativePath: { eq: "utkarsh/logo-cloudbees.png" }) {
        childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
    }
        dummyImg: file(relativePath: { eq: "use-cases/dummy.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
      }
        heroImg: file(relativePath: { eq: "technology/technology-home-hero.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE )
        }
        }
        contentWithImageOne: file(relativePath: { eq: "technology/trend-identification.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, quality: 100 )
          }
          }
          contentWithImageTwo: file(relativePath: { eq: "technology/proactive-analysis.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: NONE , quality: 100)
          }
          }
      }`